import * as React from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import {API_BASE_URL} from "../../config/config";
import LinkIcon from '@mui/icons-material/ArrowForward';
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

export default function SalonAdminSettings() {
    const getMonday = (date) => {
        date = new Date(date);
        let day = date.getDay(),
            diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
        return new Date(date.setDate(diff));
    }

    const api = `${API_BASE_URL}`;
    const [t] = useTranslation('global');
    const [registerLink, setRegisterLink] = React.useState('');
    const [weekActivitiesReminder, setWeekActivitiesReminder] = React.useState([false, false, false, false, false, false, false]);
    const [currentWeekStartDate, setCurrentWeekStartDate] = React.useState(getMonday(new Date()));
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);

    const generateInvitationLink = () => {
        fetch(`${api}/user/registerToken/generate`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.text();
            })
            .then(text => {
                setRegisterLink(`https://atoll.website/register/${text}`);
            })
    };
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function sleepToLoadVisits(){
        await sleep(1000);
        changeWeek(0);
    }
    const getWeekActivitiesReminder = () => {
        fetch(`${api}/salon/twilio/hasActivityOccurred/${currentWeekStartDate.toLocaleDateString()}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();  // Assuming the response is a JSON array of booleans
            })
            .then(data => {
                setWeekActivitiesReminder(data);
            })
    };

    useEffect(() => {
        getWeekActivitiesReminder();
    }, [currentWeekStartDate]);

    const changeWeek = (direction) => {
        setCurrentWeekStartDate(prevDate => {
            const newDate = new Date(prevDate);
            newDate.setDate(newDate.getDate() + (7 * direction));
            return getMonday(newDate);
        });
    };

    const formatDate = (date) => {
        if(!date) return '';
        return date.toLocaleDateString(); // Format the date as needed
    };

    const handleClickOpen = (date) => {
        setSelectedDate(date);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleSubmit = () => {
        console.log(`Selected date: ${formatDate(selectedDate)}`);
        fetch(`${api}/salon/twilio/sendVisitRemindSMS/${formatDate(selectedDate)}`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include',
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        }).then(data => {
            console.log(data)
        }).catch(error => {
            console.error('Error:', error);
        });
        sleepToLoadVisits().then(r =>handleClose());
          // Close the dialog after submitting
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ display: 'flex', width: '80%' }}>
                <Button onClick={generateInvitationLink} variant="contained" endIcon={<LinkIcon />}>
                    {t("managePageAdmin.generateRegistrationLink")}
                </Button>
                <div>{registerLink}</div>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                {/* Week Navigation */}
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px'}}>
                    <IconButton onClick={() => changeWeek(-1)} sx={{marginRight: '10px', color: '#FF5722'}}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <Box sx={{fontSize: '18px', fontWeight: 'bold', color: '#333'}}>
                        {currentWeekStartDate.toLocaleDateString()} - {new Date(currentWeekStartDate.getTime() + 6 * 86400000).toLocaleDateString()}
                    </Box>
                    <IconButton onClick={() => changeWeek(1)} sx={{marginLeft: '10px', color: '#FF5722'}}>
                        <ArrowForwardIcon/>
                    </IconButton>
                </div>

                {/* Week Days Display */}
                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                    {weekActivitiesReminder.map((activity, index) => {
                        const date = new Date(currentWeekStartDate);
                        date.setDate(date.getDate() + index);

                        return (
                            <Box
                                sx={{
                                    border: `3px solid ${activity ? '#399918' : '#cd1818'}`, // Green for true, Red for false
                                    borderRadius: '10px', // Rounded corners for modern look
                                    padding: '10px',
                                    margin: '5px',
                                    textAlign: 'center',
                                    backgroundColor: activity ? '#e7f0dc' : '#ffeadd', // Light green/red background based on activity
                                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                                    width: '120px',
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    color: '#333',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    cursor: !activity ? 'pointer' : 'default',
                                    transition: 'all 0.3s ease', // Smooth transition
                                    '&:hover': {
                                        transform: 'scale(1.1)' // Scale the box
                                    }
                                }}
                                onClick={() => !activity && handleClickOpen(date)}  // Open dialog only for red (inactive) days
                            >
                                <span style={{fontWeight:'bold'}}>{formatDate(date)}</span>
                                <span>{activity ? "Wysłano" : "Nie wysłano"}</span>
                            </Box>
                        );
                    })}
                </div>

                {/* Dialog for Red Days */}
                <Dialog open={openDialog} onClose={handleClose}>
                    <DialogTitle>Przypomnienie {formatDate(selectedDate)}</DialogTitle>
                    <DialogContent>
                        Czy chcesz wysłać przypomnienie dla klientów z wizytami dnia {formatDate(selectedDate)}?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Nie</Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary">
                            Tak
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
}
