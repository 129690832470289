import * as React from 'react';
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import {API_BASE_URL} from "../../config/config";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import {DatePicker, dayCalendarSkeletonClasses, TimePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import DOMPurify from "dompurify";
import {useEffect} from "react";
import {Alert} from "@mui/material";
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
export default function DashboardVisit(props) {
    const [checkComment, setCheckComment] = React.useState('');
    const [checkVisitDialogOpen, setCheckVisitDialogOpen] = React.useState(false);
    const [checkDialogOpen, setCheckDialogOpen] = React.useState(false);
    const [deleteComment, setDeleteComment] = React.useState('');
    const [alertMessageType, setAlertMessageType] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const handleAction = async () => {
        await props.onActionComplete();
    };
    const api = `${API_BASE_URL}`
    const [open, setOpen] = React.useState(false);
    const [t] = useTranslation('global');
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [price, setPrice] = React.useState();
    const [duration, setDuration] = React.useState();
    const [client, setClient] = React.useState();
    const [room, setRoom] = React.useState();
    const rooms = ["0","1","2","3","4","5"];
    const[visitDate, setVisitDate] = React.useState('');
    const [visitTime, setVisitTime] = React.useState('');
    const [procedure, setProcedure] = React.useState();
    const [comment, setComment] = React.useState();
    const [worker, setWorker] = React.useState();
    const [id, setId] = React.useState();
    const [phoneNumber, setPhoneNumber] = React.useState();
    const setInitialValues = () => {
        setClient(props.client);
        setProcedure(props.procedure);
        setDuration(props.durationInMinutes);
        setRoom(props.room);
        setPrice(props.price);
        setVisitTime(props.startTime);
        setVisitDate(props.date)
        setComment(props.comment);
        setWorker(props.worker);
        setId(props.id);
        setPhoneNumber(props.phoneNumber);
    }
    useEffect(() => {
        setInitialValues();
    }, []);
    const colorBorder = props.borderColor;
    const color = props.color;
    const hour = props.startTime;
    const completed = props.completed;
    const numberOfRows = parseInt(props.durationInMinutes);
    const startTime = parseInt(props.startTime.split(':')[0]);
    const startTimeMin = parseInt(props.startTime.split(':')[1]);
        const time = (startTime) => {
            return (startTime - 7) * 60 + startTimeMin+1;
    }
    const eventStyle = {
        height: duration*2,
        gridRowStart: time(startTime),
        gridRowEnd: time(startTime) + numberOfRows,
    };

    const checkVisit = () => {
        fetch(`${api}/user/authorize/userData`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            }
            return response.json();
        }).then(data => {
            var fullName = data.firstName + " " + data.lastName;
            fetch(`${api}/salon/visit/check/${id}`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                credentials:'include',
                body: JSON.stringify({comment: checkComment, visitId: id,workerNameAndSurname: fullName})
            })
                .then(response => {
                    if (!response.ok) {
                        setAlertMessage("Wystąpił błąd podczas usuwania wizyty")
                        setAlertMessageType("error")
                        setShowAlert(true);
                    }else{
                        setAlertMessage("Wysłano informację o odznaczonej wizycie")
                        setAlertMessageType("success")
                        setShowAlert(true);
                    }
                    handleAction();
                })

        })
    }

    const deleteVisit = () => {
        fetch(`${api}/user/authorize/userData`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                console.log(response);
            }
            return response.json();
        }).then(data => {
            var fullName = data.firstName + " " + data.lastName;
            fetch(`${api}/salon/visit/delete/${id}`,{
                method: 'POST',
                headers:{'Content-Type': 'application/json'},
                credentials:'include',
                body: JSON.stringify({comment: deleteComment, visitId: id,workerNameAndSurname: fullName})
            })
                .then(response => {
                    if (!response.ok) {
                        setAlertMessage("Wystąpił błąd podczas usuwania wizyty")
                        setAlertMessageType("error")
                        setShowAlert(true);
                    }else{
                        setAlertMessage("Wysłano informację o usuniętej wizycie")
                        setAlertMessageType("success")
                        setShowAlert(true);
                    }
                    handleAction();
                })
        })


    }
    const handleCloseDeleteEdit = () => {
        setDeleteComment("");
        setDeleteDialogOpen(false);
        setShowAlert(false);
    }
    const handleCloseCheckEdit = () => {
        setCheckComment("");
        setCheckDialogOpen(false);
        setShowAlert(false);
    }

    const addVisitForm =(e)=>{
        if ((procedure==="") || (duration==="") || (worker==="") || (client==="") || (visitDate==="") || (visitTime==="")) {
            return; // Return early from the function
        }
        setOpenEditBox(false);
        const sanitizedWorker1 = DOMPurify.sanitize(worker);
        const sanitizedClient = DOMPurify.sanitize(client);
        const sanitizedProcedure = DOMPurify.sanitize(procedure);
        const sanitizedPrice = DOMPurify.sanitize(price);
        const sanitizedDuration = DOMPurify.sanitize(duration);
        const sanitizedRoom = DOMPurify.sanitize(room);
        const sanitizedComment = DOMPurify.sanitize(comment);
        let date = visitDate.split('-')[0]+"-"+parseInt(visitDate.split('-')[1])+"-"+parseInt(visitDate.split('-')[2]);
        const visitAddData = {id: id, date:date, time:visitTime, workerNameAndSurname:sanitizedWorker1, clientFirstAndLastName:sanitizedClient, procedureName:sanitizedProcedure, price:sanitizedPrice.split('.')[0], duration:sanitizedDuration, room:sanitizedRoom, comment:sanitizedComment}
        fetch(`${api}/salon/visit/update`,{
            method: 'POST',
            headers:{'Content-Type': 'application/json'},
                    body:JSON.stringify(visitAddData),
            credentials:'include'
        })
            .then(response => {
                handleAction();
                if (!response.ok) {
                }
            })
    }

    const handleDeleteClick = () =>{
        setDeleteDialogOpen(true);
    }
    const handleCheckClick  = () => {
        setCheckDialogOpen(true);
    }
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');
    const [worker1, setWorker1] = React.useState([]);
    const [client1, setClient1] = React.useState([]);
    const [procedure1, setProcedure1] = React.useState([]);



    const [openEditBox, setOpenEditBox] = React.useState(false);
    const handleEditButton = () => {
        setInitialValues();
        fetch(`${api}/salon/worker/getWorkers`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const workerNames = data.map(worker => worker.nameandsurname);
                setWorker1(workerNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        fetch(`${api}/salon/client/getAllClients`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const clientNames = data.map(client => client.nameandsurname);
                setClient1(clientNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        fetch(`${api}/salon/procedure/getAllProcedures`,{
            method: 'GET',
            headers:{'Content-Type': 'application/json'},
            credentials:'include'
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                const procedureNames = data.map(procedure => procedure.name);
                setProcedure1(procedureNames);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        setOpenEditBox(true);
    }
    const handleCloseEditBox = () => {
        setOpenEditBox(false);
    }
    return (
        <React.Fragment>
            <div style={eventStyle} className="event">
                <Box className="visit" sx={{
                    backgroundColor: color,
                    borderRight: '1px solid '+colorBorder,
                    borderTop: '1px solid '+colorBorder,
                    //borderBottom: '1px solid '+colorBorder,
                    borderColor: colorBorder,
                    transition: 'background-color 0.5s ease',
                    '&:hover': {
                        backgroundColor: colorBorder,
                    },
                    color: '#000000', // Ensure text color is black
                }}>
                    <button className="visitButton" onClick={handleClickOpen}>
                        <div style={{
                            width: '15px',
                            height: '15px',
                            backgroundColor: completed === "true" ? '#6b9080' : '#eb5e28',
                            border: '1px solid #000000',
                            borderRadius: '20px',
                            position: 'absolute',
                            top: '5%',
                            left: '86%'
                        }}></div>
                        <div style={{fontWeight: 'bold',color:"black"}}>{hour}</div>
                        <div style={{fontWeight: 'bold',color:"black"}}>{client}</div>
                        <div style={{fontWeight: 'bold',color:"black"}}>{phoneNumber}</div>
                        <div style={{fontSize: '10px',color:"black"}}>{procedure}</div>
                    </button>
                </Box>
            </div>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{m: 5, p: 0, width: '300px', textAlign: 'center'}} id="customized-dialog-title">
                    {t("calendar.visitDetails")}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {t("calendar.visitDate")}{visitDate}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitRoom")}{room}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitTime")}{hour}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitDuration")}{props.durationInMinutes}min
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitClient")}{client}
                    </Typography>
                    <Typography gutterBottom>
                        Numer Telefonu: {phoneNumber}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitEmployee")}{worker}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitProcedure")}{procedure}
                    </Typography>
                    <Typography gutterBottom>
                        {t("calendar.visitDescription")}{comment}
                    </Typography>
                    <Typography gutterBottom>
                        Cena: {price} zł
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <IconButton
                        aria-label="close"
                        onClick={handleEditButton}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton
                        aria-label="close"
                        onClick={handleDeleteClick}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <DeleteIcon />
                    </IconButton>
                    <IconButton
                        aria-label="close"
                        onClick={handleCheckClick}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CheckIcon />
                    </IconButton>
                </DialogActions>
            </BootstrapDialog>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={openEditBox}
                onClose={handleCloseEditBox}
            >
                <DialogTitle>{t("calendar.adjustVisit")}</DialogTitle>
                <FormControl>
                    <div className="formVisit">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div className="formVisitComponent">
                                <DemoContainer components={['DatePicker']} sx={{marginRight: 2}}>
                                    <DatePicker label={t("calendar.date")} sx={{width: '100%'}}
                                                required
                                                value={dayjs(visitDate, 'YYYY-MM-DD')}
                                                onChange={(newValue) => {
                                                    setVisitDate(newValue.format('YYYY-MM-DD'));
                                                }}/>
                                </DemoContainer>
                                <DemoContainer components={['TimePicker']}>
                                    <TimePicker
                                        required
                                        clearable
                                        value={dayjs(visitTime, 'HH:mm')}
                                        ampm={false}
                                        label={t("calendar.time")}
                                        sx={{width: '100%'}}
                                        onChange={(newValue) => {
                                                setVisitTime(newValue.format('HH:mm'));
                                        }}
                                    />
                                </DemoContainer>
                            </div>
                            <div className="formVisitComponent">
                                <Autocomplete
                                    required
                                    disablePortal
                                    id="combo-box-demo"
                                    options={worker1}
                                    value={worker}
                                    sx={{width: 300, marginTop: 2}}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t("calendar.worker")}/>}
                                    onChange={(event, newValue) => {
                                        setWorker(newValue);
                                    }}
                                />

                            </div>
                            <div className="formVisitComponent">
                                <Autocomplete
                                    freeSolo
                                    id="free-solo-demo"
                                    options={client1}
                                    value={client}
                                    sx={{width: 300, marginTop: 2}}
                                    renderInput={(params) => <TextField {...params} label={t("calendar.client")}/>}
                                    onChange={(event, newValue) => {
                                        setClient(newValue);
                                    }}
                                    onInputChange={(event, newInputValue) => {
                                        setClient(newInputValue);
                                    }}
                                />

                            </div>
                            <div className="formVisitComponent">
                                <Autocomplete
                                    required
                                    disablePortal
                                    id="combo-box-demo"
                                    options={procedure1}
                                    value={procedure}
                                    sx={{width: 300, marginTop: 2}}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t("calendar.procedure")}/>}
                                    onChange={(event, newValue) => {
                                        setProcedure(newValue);
                                    }}
                                />
                            </div>
                            <div className="formVisitComponent">
                                <TextField
                                    sx={{width: 300, marginTop: 2, marginRight: 2}}
                                    id="outlined-number"
                                    label={t("calendar.price")}
                                    type="number"
                                    value={price}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setPrice(e.target.value)}
                                />
                                <TextField
                                    sx={{width: 300, marginTop: 2}}
                                    required
                                    id="outlined-number"
                                    label={t("calendar.duration")}
                                    type="number"
                                    value={duration}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={(e) => setDuration(e.target.value)}
                                />
                            </div>
                            <div className="formVisitComponent">
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={rooms}
                                    value={room}
                                    sx={{marginTop: 2}}
                                    renderInput={(params) => <TextField {...params}
                                                                        label={t("calendar.room")}/>}
                                    onChange={(event, newValue) => {
                                        setRoom(newValue);
                                    }}
                                />
                            </div>
                            <div className="formVisitComponent">
                                <TextField
                                    sx={{marginTop: 2}}
                                    id="outlined-multiline-static"
                                    label={t("calendar.comment")}
                                    multiline
                                    value={comment}
                                    rows={3}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                            <div className="formVisitComponent"><br/></div>
                            <Button variant="contained"
                                    endIcon={<SendIcon/>}
                                    onClick={addVisitForm}
                            >
                                {t("SalonManager.updateVisit")}
                            </Button>
                            <div className="formVisitComponent"><br/></div>
                        </LocalizationProvider>
                    </div>
                </FormControl>
            </Dialog>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={deleteDialogOpen}
                onClose={handleCloseDeleteEdit}
            >
                <DialogTitle>Dodaj komentarz do usunięcia wizyty</DialogTitle>
                <div style={{ width:350,display:'flex',marginRight: 'auto',marginLeft: 'auto'}}>
                    {showAlert &&
                        <Alert severity={alertMessageType}
                               variant="outlined"
                        ><b>{alertMessage}</b></Alert>
                    }
                </div>
                <div className="formVisit">
                    <FormControl>
                        <TextField
                            sx={{width: 500, marginTop: 2}}
                            id="outlined-multiline-static"
                            label={t("calendar.comment")}
                            multiline
                            value={deleteComment}
                            rows={3}
                            onChange={(e) => setDeleteComment(e.target.value)}
                        />
                    </FormControl>
                    <Button variant="contained"
                            endIcon={<SendIcon/>}
                            onClick={deleteVisit}
                            sx={{width: 200, marginTop: 2, marginBottom: 2}}
                    >
                        Usuń wizytę
                    </Button>
                </div>
            </Dialog>
            <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={checkDialogOpen}
                onClose={handleCloseCheckEdit}
            >
                <DialogTitle>Dodaj komentarz do odznaczenia wizyty</DialogTitle>
                <div style={{ width:350,display:'flex',marginRight: 'auto',marginLeft: 'auto'}}>
                    {showAlert &&
                        <Alert severity={alertMessageType}
                               variant="outlined"
                        ><b>{alertMessage}</b></Alert>
                    }
                </div>
                <div className="formVisit">
                    <FormControl>
                        <TextField
                            sx={{width: 500, marginTop: 2}}
                            id="outlined-multiline-static"
                            label={t("calendar.comment")}
                            multiline
                            value={checkComment}
                            rows={3}
                            onChange={(e) => setCheckComment(e.target.value)}
                        />
                    </FormControl>
                    <Button variant="contained"
                            endIcon={<SendIcon/>}
                            onClick={checkVisit}
                            sx={{width: 200, marginTop: 2, marginBottom: 2}}
                    >
                        Odznacz wizytę
                    </Button>
                </div>
            </Dialog>
        </React.Fragment>
    );
}